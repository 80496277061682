import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import { ImCross } from "react-icons/im";
import { LuCopyCheck } from "react-icons/lu";
import { GoCopy } from "react-icons/go";
import QRCode from "react-qr-code";
import { alertActions } from '../../_actions';

class Login extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    // this.otpSubmit = this.otpSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.onClickGetQR = this.onClickGetQR.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      otpSentLogin: false,
      showPassword: false,
      isPaymentRecive: false,
      rememberMeChecked: false,
      copiedTxId: null,
      CoinName: null,
      paymentSuccess: false
    }
  }

  static getDerivedStateFromProps(nextProps) {

    if (nextProps.users.isBalanceFatchSuccess) {
      return {
        ...nextProps,
        isPaymentRecive: true
      }
    } else {
      return {
        ...nextProps
      }
    }
  }
  copyTxIdToClipboard = (walletAdd) => {
    const tempInput = document.createElement('input');
    tempInput.value = walletAdd;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    // setCopiedTxId(walletAdd);
    this.setState({ copiedTxId: walletAdd })
    this.props.dispatch(alertActions.success("Copied!"));
  };


  inputChange(e) {
    if (e) {
      e.preventDefault();
      console.log('e_________________________', e.target);
      const { name, value } = e.target;
      this.setState({ [name]: value });
      let fieldslogin = this.state.fieldslogin;
      let errorslogin = this.state.errorslogin;
      fieldslogin[name] = value;
      errorslogin[name] = "";
      this.setState({ fieldslogin, errorslogin });
    }
  }

  // onClickGetQR(e) {
  //   if (e) {
  //     e.preventDefault();
  //     console.log('e_________________________', e.target);
  //     const { name, value } = e.target;
  //     this.setState({ [name]: value });
  //     let fieldslogin = this.state.fieldslogin;
  //     let errorslogin = this.state.errorslogin;
  //     fieldslogin[name] = value;
  //     errorslogin[name] = "";
  //     this.setState({ fieldslogin, errorslogin });
  //     if (value) {
  //       let resData = {
  //         id: value
  //       }
  //       console.log('resData_______________________', resData);
  //       this.props.dispatch(userActions.getNewAddressToBuy(resData));

  //       setTimeout(() => {
  //         this.fetchData()
  //       }, 3000)

  //     }

  //   }
  // }
  onClickGetQR(value) {
    console.log('value_________________________', value);
    // if (e) {
    //   e.preventDefault();
    //   console.log('e_________________________', e.target);
    //   const { name, value } = e.target;
    //   this.setState({ [name]: value });
    //   let fieldslogin = this.state.fieldslogin;
    //   let errorslogin = this.state.errorslogin;
    //   fieldslogin[name] = value;
    //   errorslogin[name] = "";
    //   this.setState({ fieldslogin, errorslogin });
    if (value) {
      this.setState({ CoinName: value.name })
      let resData = {
        id: value.id
      }
      console.log('resData_______________________', resData);
      this.props.dispatch(userActions.getNewAddressToBuy(resData));

      setTimeout(() => {
        this.fetchData()
      }, 3000)

    }

    // }
  }

  componentDidMount() {
    let response = {
      userName: this.props.match && this.props.match.params && this.props.match.params.userName ? this.props.match.params.userName : null,
      token: this.props.match && this.props.match.params && this.props.match.params.token ? this.props.match.params.token : null,
    }
    console.log('response___________________________', response);
    this.props.dispatch(userActions.login(response))
  }
  fetchData() {
    let { users } = this.props;
    let { getAddress } = users;
    if (getAddress && getAddress.address) {
      let intervalID = setInterval(() => {
        let address = getAddress && getAddress.address
        const response = { address: address }
        this.props.dispatch(userActions.fetchAndProcessBalance(response))
        if (this.state.isPaymentRecive) {
          clearInterval(intervalID);
          this.setState({ paymentSuccess: true })

        }
      }, 3000);
    }
  }

  handleGoHome() {
    this.props.history.push(`#/payment`)
  }

  render() {
    let { registeruser, users } = this.props;
    let { coinItems, getAddress, loading } = users;
    let walletAdd = getAddress && getAddress.address
    let { copiedTxId, paymentSuccess, CoinName } = this.state
    console.log('registeruser______________________', walletAdd);
    console.log('paymentSuccess________________________', paymentSuccess);

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        {paymentSuccess ?
          <>

            <div className="bg-gradient-to-t to-[#50d144] from-[#454255] w-full h-screen flex flex-col justify-center items-center space-y-2">

              <h1 className="text-8xl font-[900] text-center">Payment Success.</h1>
              <button className="text-xl font-[650] justify-center text-white underline" onClick={() => this.handleGoHome()}>Go Home</button>


            </div>

          </>
          :
          registeruser ? <>
            <section className=" p-10 w-full bg-white border-b ">

              {/* <div className='flex justify-center items-center text-[#213360] space-y-1 pt-12'>
                <div>
                  <select class="form-select border-1 px-1 py-1 border-gray-600 text-gray-600 bg-gray-400 rounded text-sm shadow w-64 ease-linear transition-all duration-150" aria-label="Default select example"
                    onChange={this.onClickGetQR}
                    id="coinId" name="coinId"
                    value="" >
                    <option selected>Please Select Coin</option>
                    {
                      coinItems && coinItems.length > 0 ?
                        coinItems.map((element, index) => (
                          <option value={element && element.id ? element.id : null} key={index}>{element && element.name ? element.name : "NA"}</option>
                        )) : null
                    }
                  </select>
                </div>
              </div> */}


              <div className="grid lg:grid-cols-5 grid-cols-3 gap-6">
                {coinItems && coinItems.length > 0 ?
                  coinItems.map((element, index) => (
                    <button className="flex justify-center items-center text-[#e4e0e0] bg-[#3B74A0] space-y-1 mt-10 px-6 py-2 rounded-md" onClick={() => this.onClickGetQR(element)} >{element && element.name ? element.name : null}</button>
                  )) : null}
              </div>



            </section>

            <div className="   overflow-y-auto bg-white">



              {CoinName ? <div className='flex justify-center items-center'>
                <div className=" text-[#e4e0e0] bg-[#3B74A0] space-y-1 mt-10 px-6 py-2 rounded-md">{CoinName ? CoinName : null}</div>
              </div>
                :
                null}

              <div className='flex justify-center items-center p-12'>
                {walletAdd ?
                  <div className='border border-gray-600'>
                    <QRCode
                      size={256}
                      value={walletAdd}
                      className='w-64 h-64'
                    />
                  </div>
                  : null}
              </div>


              <div className='px-6 md:w-[32rem] w-11/12  mx-auto rounded-[24px]'>

                {walletAdd ? <div className='flex justify-between items-center border bg-transparent border-gray-400 text-gray-400 lg:text-sm text-[10px] rounded-lg w-full p-2.5'>
                  <span>
                    {walletAdd && walletAdd}
                  </span> <span className=''>
                    <button
                      onClick={() => this.copyTxIdToClipboard(walletAdd)}
                      className="ml-2 text-center text-blue-500 cursor-pointer"
                      disabled={copiedTxId === walletAdd}
                    >
                      {copiedTxId === walletAdd ? <LuCopyCheck className='w-7 h-7 text-[#007F00]' /> : <GoCopy className='w-7 h-7' />}
                    </button>
                  </span>
                </div>
                  :
                  null}

              </div>
            </div>
          </>
            :

            <>

              <div className="bg-gradient-to-t to-[#5B4DCA] from-[#2D2664] w-full h-screen flex justify-center items-center">
                <h1 className="text-8xl font-[900] text-center"> Access denied</h1>

              </div>

            </>
        }

      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSentLogin, registeruser, loading } = state.authentication;
  const { users, authentication } = state;
  return {
    loggingIn,
    otpSentLogin,
    user,
    users,
    registeruser,
    loading,
    authentication
  };
}
export default connect(mapStateToProps)(Login);