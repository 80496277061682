import { userConstants } from '../_constants';

let user = JSON.parse(window.sessionStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {



    case userConstants.LOGIN_FIRST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.LOGIN_FIRST_SUCCESS:
      return {
        ...state,
        addOtpSuccess: true,
        registeruser: action.user.userinfo.data,
        otpSentLogin: true,
        loading: false,
      };
    case userConstants.LOGIN_FIRST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}