import React from "react";
import { Route, Switch, withRouter, } from "react-router-dom";
import Header from "../Header";
import Sidebar from "../Sidebar";

function Layout(props) {

  const currentPath = props.location.pathname.split('/');
  const dashboard = currentPath[2];

  return (
    <div >
      <>
        <div className="overflow-hidden bg-[#0000] ">
          <div className="bg-[#0000] ">
            <div className="flex flex-col justify-center h-screen">
              <div className="flex h-screen overflow-hidden" >
                <Sidebar />
                <div className="flex flex-col flex-1 w-full overflow-hidden overflow-y-auto">
                  <Header history={props.history} currentPath={dashboard} name="" />
                  <Switch>
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);


