import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions, dashboardActions } from '.';
// import { history } from '../_helpers';
export const userActions = {
    fetchAndProcessBalance,
    getNewAddressToBuy,
    getCoinList,
    login,
    logout,
    logout1
};


function getNewAddressToBuy(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.getNewAddressToBuy(data)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userConstants.GET_NEW_ADDRESS_TO_BUY_REQUEST } }
    function success(user) { return { type: userConstants.GET_NEW_ADDRESS_TO_BUY_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_NEW_ADDRESS_TO_BUY_FAILURE, error } }
}

function fetchAndProcessBalance(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.fetchAndProcessBalance(data)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userConstants.FETCH_AND_PROCESS_BALANCE_REQUEST } }
    function success(user) { return { type: userConstants.FETCH_AND_PROCESS_BALANCE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.FETCH_AND_PROCESS_BALANCE_FAILURE, error } }
}

function getCoinList(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.getCoinList(data)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userConstants.GET_COIN_LIST_REQUEST } }
    function success(user) { return { type: userConstants.GET_COIN_LIST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_COIN_LIST_FAILURE, error } }
}

function login(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(this.getCoinList())
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userConstants.LOGIN_FIRST_REQUEST } }
    function success(user) { return { type: userConstants.LOGIN_FIRST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FIRST_FAILURE, error } }
}

function logout() {
    return dispatch => {
        dispatch(this.logout1());
    };
}
function logout1() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}