
import { authHeader, history } from '../_helpers';
import { CONST } from '../_config';
import axios from 'axios';

import { store } from '../_helpers/store';
export const userService = {
    fetchAndProcessBalance,
    getNewAddressToBuy,
    getCoinList,
    login,
    logout
};



function fetchAndProcessBalance(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/fetchBalanceByAddress`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                fetchAndProcessBalance: user.data
            }
            return userObj;
        });

}

function getNewAddressToBuy(data) {
    console.log('data________________________', data);

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getNewAddressToBuy`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                getNewAddressToBuy: user.data
            }
            return userObj;
        });

}

function getCoinList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getCoinList`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                getCoinList: user.data
            }
            return userObj;
        });

}

function login(data) {
    console.log('data_______________________', data);

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/verifyUserName`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user
            }
            if (user.data) {
                window.sessionStorage.setItem('user', JSON.stringify(user.data));
            }

            return userObj;
        });

}

function logout() {
    window.sessionStorage.removeItem('user');
    history.push(`#/payment`);

}


function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        // console.log("handleResponse_handleResponse_handleResponse:::", data);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                // onerrorlogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}