export const userConstants = {

    LOGIN_FIRST_REQUEST: 'LOGIN_FIRST_REQUEST',
    LOGIN_FIRST_SUCCESS: 'LOGIN_FIRST_SUCCESS',
    LOGIN_FIRST_FAILURE: 'LOGIN_FIRST_FAILURE',

    GET_COIN_LIST_REQUEST: 'GET_COIN_LIST_REQUEST',
    GET_COIN_LIST_SUCCESS: 'GET_COIN_LIST_SUCCESS',
    GET_COIN_LIST_FAILURE: 'GET_COIN_LIST_FAILURE',

    GET_NEW_ADDRESS_TO_BUY_REQUEST: 'GET_NEW_ADDRESS_TO_BUY_REQUEST',
    GET_NEW_ADDRESS_TO_BUY_SUCCESS: 'GET_NEW_ADDRESS_TO_BUY_SUCCESS',
    GET_NEW_ADDRESS_TO_BUY_FAILURE: 'GET_NEW_ADDRESS_TO_BUY_FAILURE',

    FETCH_AND_PROCESS_BALANCE_REQUEST: 'FETCH_AND_PROCESS_BALANCE_REQUEST',
    FETCH_AND_PROCESS_BALANCE_SUCCESS: 'FETCH_AND_PROCESS_BALANCE_SUCCESS',
    FETCH_AND_PROCESS_BALANCE_FAILURE: 'FETCH_AND_PROCESS_BALANCE_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

};
