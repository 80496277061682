import {
  userConstants
} from '../_constants';

export function users(state = {}, action) {
  console.log('actionactionactionactionactionaction', action);

  switch (action.type) {

    case userConstants.LOGIN_FIRST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.LOGIN_FIRST_SUCCESS:
      return {
        ...state,
        addOtpSuccess: true,
        registeruser: action.user.userinfo.data,
        otpSentLogin: true,
        loading: false,
      };
    case userConstants.LOGIN_FIRST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_COIN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.GET_COIN_LIST_SUCCESS:
      return {
        ...state,
        addOtpSuccess: true,
        isBalanceFatchSuccess: false,
        coinItems: action.user.getCoinList,
        otpSentLogin: true,
        loading: false,
      };
    case userConstants.GET_COIN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_NEW_ADDRESS_TO_BUY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.GET_NEW_ADDRESS_TO_BUY_SUCCESS:
      return {
        ...state,
        addOtpSuccess: true,
        getAddress: action.user.getNewAddressToBuy,
        otpSentLogin: true,
        loading: false,
      };
    case userConstants.GET_NEW_ADDRESS_TO_BUY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.FETCH_AND_PROCESS_BALANCE_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case userConstants.FETCH_AND_PROCESS_BALANCE_SUCCESS:
      return {
        ...state,
        isBalanceFatchSuccess: true,
        // getAddress: action.user.getNewAddressToBuy,
        // otpSentLogin: true,
        loading: false,
      };
    case userConstants.FETCH_AND_PROCESS_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    default:
      return state
  }
}