import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { Menu } from '@headlessui/react'
import { FaRegUserCircle } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { MdKeyboardArrowDown, MdSupportAgent, MdNotificationsNone } from "react-icons/md";
import { withRouter } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { TbUserCog } from "react-icons/tb";
import { AiOutlineLogout } from "react-icons/ai";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: false,
    }
  }

  logout = (url) => {
    window.sessionStorage.removeItem('user');
    this.props.history.push(url);
    setTimeout(() => {
    }, 150);
  }

  componentDidMount() {
    this.props.dispatch(userActions.getProfile());
  }

  logoutSubmit = (data) => {
    confirmAlert({
      title: 'Are you sure you want to logout?',
      // message: 'Are you sure you want to logout..',
      buttons: [
        {
          label: 'Logout',
          onClick: () => this.logout('/payment')
        },
        {
          label: 'Cancel'
        }
      ]
    });
  }

  classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
    console.log('this.props____:', this.props);
  }

  handleOpen = () => {
    this.setState({ activeTab: true });
  };

  handleFalse = () => {
    this.setState({ activeTab: false });
  };

  render() {

    let { users } = this.props;
    let { getProfile } = users;
    const { activeTab } = this.state;
    const imageSrc = getProfile && getProfile.userInfo && getProfile.userInfo.imageLinkUrl;
    console.log('getProfileDataPigetProfileDataPigetProfileDataPi', getProfile);

    return (
      <>

        <div className="relative z-10 h-18 ">
          <div className="flex items-center justify-between px-4 xl:py-8 py-2">
            <div className="w-full">

            </div>
            
            <div className="flex items-center justify-end py-1 w-full">
              <Menu as="div" className="relative flex items-center space-x-1 text-left lg:space-x-4">
                {({ open }) => (
                  <>
                    <div className="relative pr-2 lg:ml-6" >
                      <Menu.Button className="flex items-center max-w-xs text-sm rounded-full lg:space-x-1 focus:outline-none focus:shadow-outline">
                        {imageSrc ? (
                          // <img className="w-12 h-12 rounded-full" src={getProfile && getProfile.userInfo && getProfile.userInfo.imageLinkUrl ? getProfile.userInfo.imageLinkUrl : ''} alt={getProfile && getProfile.userInfo && getProfile.userInfo.image ? getProfile.userInfo.image : ''} />
                          <FaRegUserCircle className="text-[#ffff] w-8 h-8" />
                        ) : (
                          <FaRegUserCircle className="text-[#ffff] w-8 h-8" />
                        )}
                        <p className="text-[#FFFFFF] text-[10px] font-[500]">{getProfile && getProfile.userInfo && getProfile.userInfo.firstName ? getProfile.userInfo.firstName : ''} {getProfile && getProfile.userInfo && getProfile.userInfo.lastName ? getProfile.userInfo.lastName : ''}</p>
                        <MdKeyboardArrowDown className="text-[#ffff]" size={25} />
                      </Menu.Button>
                    </div>
                    <Transition show={open} as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items static className="absolute right-0 mt-2 font-medium origin-top-right bg-white rounded-md w-44 top-8 shadow-3xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) =>
                            (<span onClick={() => this.logoutSubmit('/login')} className={this.classNames(active ? 'bg-red-500 text-red-500 hover:bg-red-400 hover:text-white' : 'text-red-500 ', 'transition ease-in-out duration-500 cursor-pointer px-4 py-2 font-semibold text-sm flex items-center')}><span className="pr-3"><AiOutlineLogout className="w-5 h-5" /></span> Logout</span>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>

      </>
    )
  }
}




function mapStateToProps(state) {
  const { loggingIn, user, otpSent } = state.authentication;
  const { users } = state;

  return {
    loggingIn,
    otpSent,
    user,
    users
  };
}

export default withRouter(connect(mapStateToProps)(Header));